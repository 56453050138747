<script setup>
import { onMounted, ref } from "vue";
import { axios } from "@/utils/axios";
import AppTicket from "@/components/Tickets/AppTicket";
import { store } from "@/store";
import useApi from "@/composables/useApi";
const tickets = ref([]);

const { getTickets } = useApi().ticket;

const loadTickets = async () => {
  const data = await getTickets(store.user.id);
  return data.payload;
};

onMounted(async () => {
  try {
    store.APP_LOADING = true;
    tickets.value = await loadTickets();
  } catch (error) {
    console.log(error);
  } finally {
    store.APP_LOADING = false;
  }
});
</script>
<template lang="pug">
.tickets
  app-ticket(v-for="(ticket, index) in tickets" :ticket="ticket" :key="index")
</template>

<style lang="scss" scoped>
.tickets {
  @apply grid;
  @apply grid-cols-1;
  @apply gap-4;
  @screen sm {
    @apply grid-cols-2;
  }
  @screen md {
    @apply grid-cols-2;
  }
  @screen xl {
    @apply grid-cols-3;
  }
}
</style>
